<!-- <pre>{{ section_transactions | json }}</pre>
<pre>{{ transaction | json }}</pre> 
<pre>{{ buro | json }}</pre>
<pre>{{ section_transaction | json }}</pre>

loading_analysis:any;
analysis_response:any;
status_approved:any;
start_psicometrico:any;
-->

<div class="container mt-2  mt-md-4" *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition != 'COMPLETE'">
    <app-approve-conditions (reset)="handleReset($event)"></app-approve-conditions>
</div>

<div class="container mt-2  mt-md-4" *ngIf="
aceptTermsAccount && 
section_transaction['TERMS_AND_CONDITIONS']?.condition == 'COMPLETE' &&
status_approved == 'APPROVED'">
    <approve-conditions-account (reset)="handleReset($event)"></approve-conditions-account>
</div>




<div class="container mt-2  mt-md-4" *ngIf="!loading_analysis  && !status_approved && section_transaction['TERMS_AND_CONDITIONS']?.condition == 'COMPLETE'">

    <div class=" col-12  mt-4 ">
        <div class="d-flex w-100">
            <div class="cupo-i">
                <i class="fas fa-info-circle text-warning "></i>
            </div>
            <div class="">
                <div class="d-flex w-100">
                    <div>
                        <span class="ms-2 ">¡Te damos la bienvenida a CrediCanguro!</span>
                    </div>

                </div>

                <div>
                    <span class="ms-2 text-secondary small">
                       <div class="fw-bold">¡Falta poco para concretar tu registro! 🥳</div>
                       Para finalizar dirígete con nuestros promotores e indica que ya has aceptado nuestros Términos y Condiciones 🤝 facilita la información requerida por los mismos y así formalizar tu solicitud.
                                            </span>
                </div>

            </div>
        </div>
    </div>
</div>

<!--  && section_transaction['ANALYSIS']?.condition == 'COMPLETE' -->
<div class="container mt-2  mt-md-4" *ngIf="status_approved == 'REFUSED'">
    <div class="d-flex w-100">
        <div class="cupo-i">
            <i class="fas fa-info-circle text-primary "></i>
        </div>
        <div class="ms-2 ">
            <div class="d-flex w-100">
                <div>
                    <span class="fw-bold">¡Te damos la bienvenida a CrediCanguro!</span>
                                </div>

            </div>

            <div class="mt-2">
                <span class=" text-secondary ">

                    <span class="fw-bold">¡Tu solicitud no ha sido aprobada!  </span><br>
                    La negación o aprobación del Plan de Financiamiento lo realiza el sistema de manera automática luego de analizar los datos financieros consultados.
                   Sin embargo, quedarás en una lista de espera para que puedas optar por una segunda oportunidad.

                </span>


            </div>
        </div>


    </div>
</div>

<div class="container mt-2  mt-md-4" *ngIf="!show_response_REFUSED && status_approved == 'REFUSED' && section_transaction['ANALYSIS']?.condition == 'COMPLETE'">
    <div class="d-flex w-100">
        <div class="cupo-i">
            <i class="fas fa-info-circle text-primary "></i>
        </div>
        <div class="ms-2 ">
            <div class="d-flex w-100">
                <div>
                    <span class="fw-bold">CrediTotal  te informa</span>
                </div>

            </div>

            <div class="mt-2">
                <span class=" text-secondary ">
                    Nuestro sistema se encuentra analizando tu historial financiero ⚙️<br>
                    Esto puede tardar entre 5 a 60 minutos ⏳<br>
                    Pronto te indicaremos el estatus de tu solicitud 📲<br>
            </span>

            </div>

        </div>
    </div>


</div>


 
<div class="container mt-2  mt-md-4" *ngIf="status_approved == 'WAIT_PSICOMETRICO' && section_transaction['ANALYSIS']?.condition == 'COMPLETE'">

    <div class="d-flex w-100">
        <div class="cupo-i">
            <i class="fas fa-info-circle text-warning "></i>
        </div>
        <div class="ms-2 ">
            <div class="d-flex w-100">
                <div>
                    
                    <span class="">CrediTotal  te informa</span>

                </div>

            </div>


            <div class="mt-2">
                <span class=" text-secondary small">
                    
                    Nuestro sistema no logró encontrar información suficiente sobre tu historial financiero.
                    Por favor, acércate a cualquiera de nuestras tiendas autorizadas e indica a nuestras promotoras que debes realizar un Test Psicométrico. 
                    Este Test Psicométrico está compuesto por preguntas que le ayudarán a nuestro sistema a entender tu comportamiento financiero y con base en los resultados sabremos si apruebas o no apruebas la solicitud. 
                                       
                </span>
            </div>

        </div>
    </div>


</div>


<div class="container mt-2  mt-md-4" *ngIf="
!aceptTermsAccount && 
section_transaction['TERMS_AND_CONDITIONS']?.condition == 'COMPLETE' &&
status_approved == 'APPROVED'  ">
    <ng-container *ngIf="!loading">

        <div class="w-100 text-center mt-2 mb-2 mb-md-4">
            
            <img style="    width: 100%; border-radius: 5px;" src="./assets/public/images/banner2.png" alt=" ">
                </div>


        <div class="d-flex w-100  mt-3 mb-1">
            <div>
                <div class="h4 text-dark text-capitalize pt-2">
                    Hola, {{person?.person?.name}}
                </div>
            </div>
            <div class="text-end ms-auto d-none d-md-block ">
                <button type="button" class="btn btn-primary" [routerLink]="['/financial/']">Mis compras</button>
            </div>
        </div>


        <div class="row mt-2">

            <div class="alert alert-danger mt-3" role="alert" *ngIf="has_overdue_debt">
                <div class="mt-1 mb-1">
                    
                    Para seguir disfrutando de tu plan de financiamiento, debes ponerte al día con tus cuotas vencidas.<br> 
                    
                    
                    Para mayor información contáctanos vía 
                    
                    <a class="text-primary" href="https://wa.me/message/OFHNZWBYTG3HO1" target="_blank">
                        Whatsapp
                    </a>

                </div>
            </div>
    

            <div class="col-12" *ngIf="!person?.person?.quota_active ">
                <div class="card  cmt2 mt-3">
                    <div class="card-body">

                        <div class="d-flex w-100 bd-highlight">
                            <div class="cupo-i  bd-highlight">
                                <i class="fas fa-money-bill-wave text-danger "></i>
                            </div>
                            <div class="flex-grow-1 bd-highlight">
                                <div class="d-flex w-100">
                                    <div>
                                        <span class="ms-2 ">Cupo Inactivo</span>

                                    </div>

                                </div>


                                <div>
                                    <span class="ms-2 text-secondary small">Su cupo esta inactivo</span>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="person?.person?.quota_active && !person?.person?.quota_client_active && !has_overdue_debt">
                <div class="card  cmt2 mt-3" (click)="toogleStatus(true)">
                    <div class="card-body">

                        <div class="d-flex w-100 bd-highlight">
                            <div class="cupo-i  bd-highlight">
                                <i class="fas fa-money-bill-wave ak-text-color-6 "></i>
                            </div>
                            <div class="flex-grow-1 bd-highlight">
                                <div class="d-flex w-100">
                                    <div>
                                        <span class="ms-2 ">Cupo bloqueado</span>

                                    </div>
                                    <div class="text-end ms-auto">
                                        <span class="text-primary cursor-pounter">Desbloquear</span>
                                    </div>
                                </div>


                                <div>
                                    <span class="ms-2 text-secondary small">Desbloquealo para realizar tu compra</span>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="person?.person?.quota_active && person?.person?.quota_client_active   && !has_overdue_debt">
                <div class="card  cmt2 mt-3" (click)="toogleStatus(false)">
                    <div class="card-body">

                        <div class="d-flex w-100 bd-highlight">
                            <div class="cupo-i  bd-highlight">
                                <i class="fas fa-money-bill-wave text-primary "></i>
                            </div>
                            <div class="flex-grow-1 bd-highlight">
                                <div class="d-flex w-100">
                                    <div>
                                        <span class="ms-2 ">Cupo habilitado</span>

                                    </div>
                                    <div class="text-end ms-auto">
                                        <span class="text-primary cursor-pounter">Bloquear</span>
                                    </div>
                                </div>


                                <div>
                                    <span class="ms-2 text-secondary small">
                                        Activado <span>{{person?.person?.date_quota_client_active  | date:'d MMM, yyyy':'es'}} </span>

                                    </span>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">

            <div class="col-6">
                <div class="card  mt mt-3">
                    <div class="card-body">
                        <span class="title">Cupo Total</span>

                        <h4 class="text-primary fw-bold mt-2  mb-0">
                            {{ quota?.total}} USD
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card mt  mt-3">
                    <div class="card-body">

                        <span class="title">Cupo disponible</span>

                        <h4 class="text-primary fw-bold mt-2 mb-0">
                            {{ quota?.disponible}} USD
                        </h4>

                        <span class="title text-secondary fw-normal">Compra ya</span>

                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-6">
                <div class="card  mt mt-3">
                    <div class="card-body">

                        <span class="title">Pagos atrasados</span>

                        <h4 class="text-primary fw-bold mt-2  mb-0">
                            {{mounto_pendiente_vencido}} USD
                        </h4>
                        <span class="title text-secondary fw-normal">
                            Manten tus pagos al dia
                        </span>

                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card  mt mt-3">
                    <div class="card-body">


                        <span class="title">Puntos acumulados</span>

                        <h4 class="text-primary fw-bold mt-2  mb-0">
                            0 Pts.
                        </h4>
                        <span class="title text-secondary fw-normal">
                            Próximamente
                        </span>
                    </div>
                </div>
            </div>
        </div>


        <div class="md-cards mt-3">
            <div class="md-card-cont">
                <mbsc-scrollview [options]="scrollViewOptions" class="demo-card">
                    <mbsc-scrollview-item mbsc-card *ngFor="let c of newUpdated">
                        <mbsc-card-content>
                            <div class="d-flex w-100 bd-highlight">
                                <div class="bd-highlight pe-2">
                                    <img class="md-img" [src]="c.image" draggable="false" style="width: 50px;">

                                </div>
                                <div class="flex-grow-1 bd-highlight">
                                    <mbsc-card-title>{{c.title}}</mbsc-card-title>
                                    <mbsc-card-subtitle>{{c.dev}}</mbsc-card-subtitle>
                                </div>
                            </div>

                        </mbsc-card-content>
                    </mbsc-scrollview-item>
                </mbsc-scrollview>

            </div>
        </div>


        <div class="d-flex  justify-content-center">

            <div class="text-center mt-4 me-2">
                Monto total pendiente

                <h3 class="text-dark fw-bold mt-2  mb-0">
                    {{mounto_pendiente}} USD
                </h3>
            </div>

            <div class="text-center mt-4 ms-2">
                Monto por conformar

                <h3 class="text-secondary fw-bold mt-2  mb-0">
                    {{mounto_por_conciliar}} USD
                </h3>
            </div>

        </div>



        <div *ngFor="let item of invoiceCredits">
               <div *ngIf="item?.installmentNexPayment?.totalpaymentReference < item?.installmentNexPayment?.dueBalance" class="card  cmt2 mt-5" [routerLink]="!direct_debit_customer.includes(item.number)?['/payment/',item?.code,item?.installmentNexPayment?.code]:null">

                <div class="card-body ">

                    <div>
                        <span class="ms-2 fw-bold  ">{{shops_obj[item?.provider_transaction?.shop]}}</span>
                    </div>

                    <div class="mt-2 ">

                        <div class="d-flex w-100 ">
                            <div>
                                <span  class="ms-2  small " [ngClass]="{'text-danger': item?.installmentNexPayment?.expired && item?.installmentNexPayment?.payment_status?.code != 'PaymentComplete'}">Próximo pago  {{item?.installmentNexPayment?.scheduledPaymentDate | date:'d MMM, yyyy':'es'}}</span>

                            </div>

                         <div class="text-end ms-auto " *ngIf="!direct_debit_customer.includes(item.number)">

                                <span class="text-dark fw-bold  fz-18 ">{{item?.installmentNexPayment?.dueBalance}} USD</span>
                                <div *ngIf="item?.installmentNexPayment?.total_remaining" class="text-danger small">

                                    +{{item?.installmentNexPayment?.total_remaining}} USD
                                </div>
                                <br>

                                <button type="button" class="btn btn-primary mt-2">Pagar</button>


                            </div>



                        </div>


                    </div>

                    <div class="mt-2 ">
                        <div class="d-flex w-100 ">

                            <div class=" ps-2 " *ngIf="direct_debit_customer.length>0">
                                <i class="fas fa-exclamation-triangle text-danger"></i> Usted posee cobro por domiciliación en proceso para este crédito, por favor no realice ningún pago para evitar duplicidad de cobros. Asegúrese de tener fondos disponibles
                                en su cuenta domiciliada para realizar el cobro automático.

                            </div>
                     
                        </div>

                        <div class="mt-3" *ngIf="!direct_debit_customer.includes(item.number)">
                            <ngb-progressbar type="info" [value]=" bard(item.dueBalance,item.totalAmount)">Copying file <b>{{ bard(item.dueBalance,item.totalAmount)}}%</b>...</ngb-progressbar>

                        </div>

                    </div>



                </div>
            </div>


        </div>





    </ng-container>

</div>



<ng-container *ngIf="loading || loading_analysis ">

    <div class="w-100 text-center ">
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>