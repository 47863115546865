import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MbscScrollViewOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LaService } from 'src/app/modules/la/servises/la.service';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'ak-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe],

})

export class DashboardComponent implements OnInit,OnDestroy {
  base_amount:any;

  scrollViewOptions: MbscScrollViewOptions = {
    layout: 'fixed',
    itemWidth: 300,
    snap: false
};


    newUpdated = [{
      image: this.sanitize('../assets/public/images/carrusel/1.jpeg'),
      title: 'Sobre Nosotros',
      dev: 'Presiona aquí para conocer más de CrediCanguro',
    }, {
    image: this.sanitize('../assets/public/images/carrusel/2.jpeg'),
    title: 'Aliados habilitados',
      dev: 'Presiona aquí para conocer a todos nuestros aliados',
    }, {
    image: this.sanitize('../assets/public/images/carrusel/3.jpeg'),
    title: 'Formas de pago',
    dev: 'Presiona aquí y descubre como pagar tus cuotas',
    }, {
    image: this.sanitize('../assets/public/images/carrusel/4.jpeg'),
    title: 'Notificaciones',
    dev: 'Presiona aquí y activa notificaciones personalizadas',
    }];



quota:any = {total:50,disponible:0,utilizado:0};
mounto_pendiente_vencido:any = 0;
mounto_pendiente:any = 0;
mounto_por_conciliar:any = 0;

shops:any = [];
shops_obj:any = {};
show_response_REFUSED:any = false;
has_overdue_debt:any = false;
load_has_overdue_debt:any = true;

direct_debit_customer: any = [];

buro: any;
loading_buro = false;
aceptTermsAccount= false;
  loading: any = true;
  isAuth: any;
  sessionUser: any;
  person: any;
  imgPerson: any;
  usQuote: any = false;

  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  installments:any;
  installmentNexPayment:any;

  invoiceCredits:any = [];
  private socketServiceSubscribe:any  = Subscription;


  loading_analysis:any = true;
  analysis_response:any;
  status_approved:any;
  start_psicometrico:any;


  remanenteInvoices:any ={};
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,

  ) {
    
  }

  

  ngOnDestroy(): void {
    this.socketServiceSubscribe.unsubscribe();
  }

  ngOnInit(): void {
    
    this.getBase_amount();
    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson();
      }
    });


    this.socketService.resetDataClientCode.subscribe((person_code: any) => {
      if(person_code && person_code == this._auth.sessionUser?.person_code){

     //   this.getPerson(false);
      }
    });
    
  

   this.socketServiceSubscribe =  this.socketService.msgDataClient.subscribe((payload: any) => {

      if(payload?.code_person == this._auth?.sessionUser?.person_code  && payload?.type ==  'CREATE_INVOICE'){
    //   this.getPerson(false);
       }
       
     });

   //  this.getshops();
  }




getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


getBase_amount = async () =>  {

  try {

    let response =  await this._user.getBase_amount();
    this.base_amount = response?.amount;

  } catch (error) {

    console.log(error);
    
    
  }

}


  getPerson = async (loadinge = true) => {

    try {
    
    const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
    this.person = resp.resp;
    if(this.person){
      this.getHas_overdue_debt();
      let customer_direct_debit = await this._user.customerDirectDebitProcesses(this.person.identitys[0].code_identity.name, this.person.identitys[0].identity);
      console.log(customer_direct_debit);
      customer_direct_debit.res.map((row: any)=> {
        if(row.invoice!==null){
          console.log(row);
          this.direct_debit_customer.push(row.invoice);
        }
      });
      //this.direct_debit_customer 
    }


    this.getCustomerPendingTAC();
  let total= this.person?.person?.current_quota? this.person?.person?.current_quota : this.base_amount;

    this.quota =  {
        total:total,
        utilizado:this.toFixedApp(total - this.person?.person?.quota ),
        disponible:this.toFixedApp(this.person?.person?.quota)
    };


    if(localStorage.getItem('active_cupo') && this.person?.person?.quota_active){

      this.toogleStatus(true); 
      localStorage.removeItem('active_cupo');

    }

    

    if (this.person?.person) {

        this.getSectionTransactions();
        this.getSection_transaction_by_person();

        const resp_invoiceCredits = await this.listClientInvoiceCredits();


        let temp =  resp_invoiceCredits.res;

        let invoiceCredits:any = [];
        let installMent_promes:any =[]

        temp?.forEach((element:any) => {

          if(element?.payment_status?.code == "PaymentPending" ){

            invoiceCredits = [...invoiceCredits,element];

            const data =  {
              code_invoice:element?.code 
             
             }
    
             installMent_promes  =  [...installMent_promes,this._user.getPersonInstallmentsInvoiceCredits(data)] ;
  
          }

        });

        let installMents_base = await Promise.all(installMent_promes);


        
        this.getRemanentInvoice(invoiceCredits,installMents_base);


        

       //this.invoiceCredits =  invoiceCredits;

        

    }
  
    this.imgPerson = this._auth.sessionUser?.file_selfie?.url;
      this.usQuote =  this.person?.person?.quota_client_active ? true:false;

    } catch (error) {
      console.error(error);

    }
  }

  

  getInvoicesAndInstallments = async (invoiceCredits_base:any,installMents_base:any,remanenteInvoices:any) => {
    try {

        
      this.invoiceCredits  = invoiceCredits_base?.map((invoice:any, index:any) => {


        
        let installments:any = installMents_base[index]?.res;

        let installmentNexPayment:any ;
        let totalPaymentConcile = 0;
        let rem:any = remanenteInvoices [invoice.code]

         installments.map((value:any) => {
          debugger;
          const fechaDada = new Date(value?.installMent?.scheduledPaymentDate);

          if (!this.compararFechas(fechaDada) && value?.installMent?.payment_status?.code != 'PaymentComplete')  {
          this.mounto_pendiente_vencido =this.toFixedApp(  this.mounto_pendiente_vencido + value.installMent.dueBalance) ;
          value.expired = true;
          value.installMent.expired = true;

          }

          value?.payments?.forEach((value2:any)=>{

            if(value2.payment.reconciled_payment){

              totalPaymentConcile = this.toFixedApp( totalPaymentConcile +  value2.payment.amount);

            }

          });

  
          value["totalpaymentReference"] = 0;
  
          value?.paymentReference?.forEach((value2:any) =>{
            value["totalpaymentReference"] = this.toFixedApp(  value["totalpaymentReference"]  +  value2?.payment?.amount);
          });

          if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !installmentNexPayment && rem?.installment?.code != value?.installMent?.code){
            installmentNexPayment = value?.installMent;
            installmentNexPayment.totalpaymentReference = value?.totalpaymentReference;

            installmentNexPayment['total_dueBalance'] = installmentNexPayment?.dueBalance;

          if(rem?.next_installment?.code == value?.installMent?.code){

            installmentNexPayment['total_dueBalance']  =  rem?.total_amount_next_installment;
            installmentNexPayment['total_remaining']  =  rem?.remaining_amount;

          }

            
          }
        
          if(value?.installMent?.payment_status?.code != 'PaymentComplete'){
            this.mounto_pendiente =this.toFixedApp(  this.mounto_pendiente + value.installMent.dueBalance );
          }

          this.mounto_por_conciliar =this.toFixedApp(  this.mounto_por_conciliar + value?.totalpaymentReference );
          

          
          
          return value;
        });

        invoice.installMent = installments;
        invoice.installmentNexPayment = installmentNexPayment;
        invoice.totalPaymentConcile = totalPaymentConcile;

        return invoice;

      });

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }

  



  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {
          code_person :this._auth.sessionUser?.person_code
         
         }

        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
          console.error(error);
        
        
      }
  }



  getSection_transaction_by_person = async (buro:any = true) => {

    try {

    const resp = await this._user.getSection_transaction_by_person({ code: this._auth.sessionUser?.person_code,type:'ONBOARDING' });

    const result = resp.resp;
    let temp: any = {};

    if (result?.section_transactions?.length > 0) {

      result?.section_transactions.map((obj: any) => {

        temp[obj?.section_transaction?.code] = obj;

       /* if (obj?.section_transaction?.code == "BURO" && obj.condition == "COMPLETE" && buro) {

              this.getDataBuro(this.person, false);    
          
          }*/

        return obj;
      });

      this.getAnalysis(this.person);

    }

    
    this.section_transaction = temp;
    this.transaction = result?.transaction;

    this.loading = false;

   
  } catch (error) {
    this.loading = false;

    console.log(error);
    
      
  }
  }


  getDataBuro = async (item: any, reload = true) => {
    this.loading_buro = true;

    try {

         if (item?.identitys?.length > 0) {
      let cde = item?.identitys[0]?.code_identity?.code == 'V'? 1: 3;
      let params = {
        "type_identity": cde,
       // "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      const buro = await this._user.getDataBuro(params);
      this.loading_buro = false;


      if(buro?.resp?.status_approved == 'REFUSED' ){

        let fechaDada:any = new Date(buro?.resp?.fechaConsulta);
        let fechaActual:any =   new Date();


        let diferenciaEnMilisegundos = fechaActual - fechaDada;
        let diferenciaEnHoras = diferenciaEnMilisegundos / (1000 * 60 * 60); // convertir milisegundos a horas


      if (diferenciaEnHoras >= 1) {
        this.show_response_REFUSED = true;

      } 
      }

      this.buro = buro?.resp;


      if (reload) {
        this.getSection_transaction_by_person(true);
      }


    }

        
    } catch (error) {
        this.loading_buro = false;
  
    }
   
  }





  getSectionTransactions = async () => {

    let filter ={type:'ONBOARDING'}

    const resp = await this._user.getSectionTransactions(filter);
    this.section_transactions = resp.resp;



  }
  

  toogleStatus = async (st = this.usQuote) => {
    
    let status = st?true:false;


    try {
      let query = {status};

      const resp = await this._user.toogleStatus(query);

      this.person.person = resp.res;
      this.usQuote = resp.res.quota_client_active;

    } catch (error) {
      this.usQuote = !status;
      console.log(error);
      
    }


  }

  bard(a:any,b:any){


   
    let resto =  this.toFixedApp( this.toFixedApp( this.toFixedApp(a) *  this.toFixedApp(100)) /  this.toFixedApp(b));
    let result =   this.toFixedApp(this.toFixedApp(100 )- this.toFixedApp((resto)));
    return result;
  }

  toFixedApp = (x:any) => {

    let a:any = `e+${2}`;
    let b:any = `e-${2}`;
  
    return +(Math.round(x + a) + b);
  
    //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
    
  }

sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


getCustomerPendingTAC = async () => {
  this.loading = true;


  try {

    let res:any = await this._user.getCustomerPendingTAC({ code_person: this._auth.sessionUser?.person_code });
    
    let accounts = res.resp;
    if(accounts?.length > 0 ){

      this.aceptTermsAccount = true;

    }else{

      this.aceptTermsAccount = false;


    }

  } catch (error) {
    console.error(error);
    this.loading = false;

  }

}


handleReset(data:any){
  this.getPerson();

}



getRemanentInvoice = async (invoices:any,installMents_base:any) => {

  let promes_reman:any = [];
  
  
  try {



    invoices.map((value:any)=>{

      promes_reman = [...promes_reman,
        this._user.getRemanentInvoice({
          "invoiceCode": value.code,
          "status": true
         })
      ] 
    
    });
  

    let remanentInvoices = await Promise.all(promes_reman);
    let remanenteInvoices:any = {};
    remanentInvoices?.map((value:any)=>{

      value?.map((value2:any)=>{


       remanenteInvoices[value2.invoice.code] = value2;
      });    
    });

    this.remanenteInvoices = remanenteInvoices;

    this.getInvoicesAndInstallments(invoices,installMents_base,remanenteInvoices);



  } catch (error) {
    console.error(error);

  }
}


getHas_overdue_debt = async () => {
    

  try {

    let query = {

      codePerson: this.person.person?.code
      
    };

    const resp = await this._user.has_overdue_debt(query);
    this.has_overdue_debt = resp?.result?true:false;

  } catch (error) {
    console.log(error);
    
  }


}
compararFechas(fecha:any) {
 const hoy = new Date();
 const fechaActual = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate());
 
 const otraFecha = new Date(fecha);
 
 return otraFecha > fechaActual;
}



getAnalysis = async (item: any, reload = true) => {
  
  debugger
  try {

    this.loading_analysis = true;

    let params = {
      codePerson: item?.person?.code
    };

    const analysis = await this._user.getAnalysis(params);

    this.analysis_response =  (Object.keys(analysis?.result).length > 0 && analysis?.result?.constructor === Object)? analysis?.result : null;

    if(this.analysis_response.transaction_status == 'INCOMPLETE'){
      this.analysis_response.showStatusValue = 'Pendiente';
  }else if(this.analysis_response.transaction_status == 'COMPLETE'){
    this.analysis_response.showStatusValue = 'Completa';
  }
  
  this.status_approved = this.analysis_response.approve_status;

  if(this.analysis_response.approve_status == 'REFUSED'){


  this.analysis_response.showStatusApprove = 'Rechazado';

  }else if(this.analysis_response.approve_status == 'APPROVED'){
    this.analysis_response.showStatusApprove = 'Aprobado'
  }else if(this.analysis_response?.approve_status == 'PENDING' && this.analysis_response?.next_approve_type == 'BURO_PSICOMETRICO'){
    
      this.start_psicometrico = true;

      this.analysis_response.showStatusApprove = 'En espera por psicometrico';

      this.status_approved = 'BURO_PSICOMETRICO';

    }
      this.loading_analysis = false;

    } catch (error) {

      this.loading_analysis = false;

    }
   
  };
  
  

}
